import {graphql} from 'gatsby'
import React from 'react'

import {Layout} from '../../../components'
import {
  ThemePageHeader,
  ThemeFeatureCard,
  FeatureCards,
  Features,
  FeaturesList,
} from '../../../components/ThemePage'
import CTA, {CTAItem} from '../../../components/CTA'

import MembershipIcon from '../../../images/inline-svgs/online-payment.svg'
import GhostIcon from '../../../images/inline-svgs/ghost.svg'
import DocumentationIcon from '../../../images/inline-svgs/documents.svg'
import DownloadIcon from '../../../images/inline-svgs/download.svg'
import ResponsiveIcon from '../../../images/inline-svgs/responsive.svg'
import FAQIcon from '../../../images/inline-svgs/faq.svg'

const ElementaryGhostTheme = ({data}) => (
  <Layout>
    <ThemePageHeader
      title="Elementary"
      eyebrow="Ghost"
      description="A bold and minimal ghost theme with a focus on building a great reading experience. "
      price="$ 30"
      purchaseLink="https://gumroad.com/l/elementary-ghost-theme"
      demoLink="https://elementary.stylesheets.dev/"
      image={data.header.childImageSharp.gatsbyImageData}
      eyebrowLink="/themes/ghost/"
    />

    <FeatureCards>
      <ThemeFeatureCard
        title="Ghost 3.x"
        description="Supports latest version of Ghost."
      >
        <GhostIcon fill="#444" width="40px" />
      </ThemeFeatureCard>
      <ThemeFeatureCard
        title="Memberships Support"
        description="Add Ghost memberships and subscription feature to your site."
      >
        <MembershipIcon fill="#444" />
      </ThemeFeatureCard>
      <ThemeFeatureCard
        title="Fully Documented"
        description="Fully documented for setting up and making customizations on your site."
      >
        <DocumentationIcon fill="#444" />
      </ThemeFeatureCard>
    </FeatureCards>

    <Features>
      <FeaturesList>
        <li>Support latest versions of Ghost 3.0+</li>
        <li>Supports all basic features of Ghost</li>
        <li>Fully customisable typography and color palette</li>
        <li>Ghost premium membership feature support</li>
        <li>Responsive design</li>
        <li>Pagination</li>
        <li>Post sharing icons for Twitter, Facebook and Pinterest</li>
        <li>
          All major social media icons included in the theme (Facebook, Twitter,
          Instagram, Github, Pinterest, Linkedin, Youtube, Tumblr, Medium,
          Reddit, 500px, Dribbble)
        </li>
        <li>Space for integrating comments from services like Disqus etc.</li>
        <li>Free and regular updates</li>
        <li>Google fonts</li>
      </FeaturesList>
    </Features>

    <CTA title="Ready to Get Started?">
      <CTAItem
        title="Purchase Theme"
        link="https://gumroad.com/l/elementary-ghost-theme"
        externalLink
      >
        <DownloadIcon fill="#fff" />
      </CTAItem>
      <CTAItem
        title="View Demo"
        link="https://elementary.stylesheets.dev/"
        externalLink
      >
        <ResponsiveIcon fill="#fff" />
      </CTAItem>
      <CTAItem
        title="Documentation"
        link="https://docs.stylesheets.dev/elementary/"
        externalLink
      >
        <DocumentationIcon fill="#fff" />
      </CTAItem>
      <CTAItem title="FAQs" link="/faq/">
        <FAQIcon fill="#fff" />
      </CTAItem>
    </CTA>
  </Layout>
)

export default ElementaryGhostTheme

export const query = graphql`
  query {
    header: file(relativePath: {eq: "themes/elementary.png"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`
